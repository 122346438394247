import {
	GET_ACCOUNT_SUCCESS,
	GET_ACCOUNT_FAIL,
	UPDATE_PROFILE,
	UPDATE_DELIVERY_INFO,
	UPDATE_DELIVERY_DETAILS_PROFILE,
	UPDATE_PASSWORD,
	GET_NEW_CODE,
	VERIFY_PHONE,
	GET_SINGLE_SETTING,
	GET_ALL_SETTING,
	GET_SHIPPING,
} from './types';
import { logout } from '../auth/actions';

import * as userService from '../../services/user.service';
// import * as mealActions from '../meal/actions';
import { SEND_VERIFICATION_LINK } from '../auth/types';

export const getAccount = () => async (dispatch) => {
	try {
		const { username } = JSON.parse(localStorage.getItem('user'));
		const response = await userService.getAccount(username);
		if (response.status >= 200 && response.status < 300) {
			const { data } = response;
			dispatch({
				type: GET_ACCOUNT_SUCCESS,
				account: data,
			});

			window.FS.identify(data.email, {
				displayName: `${data.first_name} ${data.last_name}`,
				email: data.email,
				membership: data.saleor_hospital?.hospital || ''
			})
		}
	} catch (error) {
		// If we can't get account then we should log the user out
		dispatch({ type: GET_ACCOUNT_FAIL });
		dispatch(logout());
	}
};

export const updateUserProfile =
	(updatedProfileData) => async (dispatch, getState) => {
		try {
			const { username } = JSON.parse(localStorage.getItem('user'));
			const response = await userService.editUser(username, updatedProfileData);
			if (response.status >= 200 && response.status < 300) {
				dispatch({
					type: UPDATE_PROFILE,
					profile: updatedProfileData,
				});
				dispatch(getAccount());
			}
		} catch (error) {
			const message = error.response && error.response.data;
			console.log(message);
			throw message;
		}
	};

export const updateDeliveryDetailsProfile =
	(deliveryDetailsProfile) => async (dispatch, getState) => {
		try {
			const { username } = JSON.parse(localStorage.getItem('user'));
			const response = await userService.editUser(
				username,
				deliveryDetailsProfile
			);
			if (response.status >= 200 && response.status < 300) {
				dispatch({
					type: UPDATE_DELIVERY_DETAILS_PROFILE,
					deliveryDetailsProfile,
				});
			}
		} catch (error) {
			const message = error.response && error.response.data;
			console.log(message);
			throw message;
		}
	};

export const updateDeliveryInformation =
	(updatedDeliveryInfo) => async (dispatch) => {
		try {
			const { username } = JSON.parse(localStorage.getItem('user'));
			const response = await userService.editUser(
				username,
				updatedDeliveryInfo
			);
			if (response.status >= 200 && response.status < 300) {
				dispatch({
					type: UPDATE_DELIVERY_INFO,
					deliveryInfo: updatedDeliveryInfo,
				});
			}
		} catch (error) {
			const message = error.response && error.response.data;
			console.log(message);
			throw message;
		}
	};

export const updatePassword = (passwordData) => async (dispatch, getState) => {
	try {
		const response = await userService.changePassword(passwordData);
		if (response.status >= 200 && response.status < 300) {
			dispatch({
				type: UPDATE_PASSWORD,
			});
		}
	} catch (error) {
		const message = error.response && error.response.data;
		// console.log(message)
		throw message;
	}
};

export const activateAccountVerifyPhone = (code) => async (dispatch) => {
	try {
		const response = await userService.verifyPhone(code);
		if (response.status >= 200 && response.status < 300) {
			dispatch({
				type: VERIFY_PHONE,
			});
		}
	} catch (error) {
		const errorData = error.response && error.response.data;
		console.log(errorData);
		throw errorData.error;
	}
};

export const getNewCodeForVerifyPhone = () => async (dispatch) => {
	try {
		const response = await userService.getPhoneVerificationCode();
		if (response.status >= 200 && response.status < 300) {
			dispatch({
				type: GET_NEW_CODE,
			});
		}
	} catch (error) {
		const errorData = error.response && error.response.data;
		console.log(errorData);
		throw errorData.error;
	}
};

export const sendVerificationLinkToEmail = () => async (dispatch) => {
	try {
		const response = await userService.sendEmailVerification();
		if (response.status >= 200 && response.status < 300) {
			dispatch({
				type: SEND_VERIFICATION_LINK,
			});
		}
	} catch (error) {
		const errorData = error.response && error.response.data;
		console.log(errorData);
		throw errorData;
	}
};

export const getSingleSetting = () => async (dispatch) => {
	try {
		const response = await userService.getSingleSetting();
		if (response.status >= 200 && response.status < 300) {
			const { data } = response;
			dispatch({
				type: GET_SINGLE_SETTING,
				settings: data?.Items,
			});
		}
	} catch (error) {
		const errorData = error.response && error.response.data;
		console.log(errorData);
		throw errorData;
	}
};

export const getAllSetting = () => async (dispatch, getState) => {
	try {
		const response = await userService.getAllSetting();
		const allSettings = {
			allSettings: getState().meal.userTypeSettings,
		};
		if (response.status >= 200 && response.status < 300) {
			const { data } = response;

			// const dataObject =
			// 	Object.keys(allSettings).length > 1
			// 		? window.localStorage.setItem(
			// 				'allSettings',
			// 				JSON.stringify(allSettings)
			// 		  )
			// 		: window.localStorage.setItem(
			// 				'allSettings',
			// 				JSON.stringify(
			// 					data.Items.map((item) => ({
			// 						key: item.setting_id,
			// 						value: item.value,
			// 					}))
			// 				)
			// 		  );
			dispatch({
				type: GET_ALL_SETTING,
				allSettings: data?.Items,
				userType_settings: allSettings,
			});
			// dispatch(mealActions.getMealListByHospital());
		}
	} catch (error) {
		const errorData = error.response && error.response.data;
		console.log(errorData);
		throw errorData;
	}
};

export const getShipping = () => async (dispatch) => {
	try {
		const response = await userService.getShipping();
		if (response.status >= 200 && response.status < 300) {
			const { data } = response
			dispatch({
				type: GET_SHIPPING,
				shipping: data?.price
			});

		}
	} catch (error) {
		const errorData = error.response && error.response.data;
		console.log(errorData);
		throw errorData;
	}
};
