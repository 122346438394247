import { ToBool } from '../../helpers/utils';
import UserProfile from '../../models/user-profile';
import {
	CLEAR_PROFILE,
	GET_ACCOUNT_SUCCESS,
	GET_ACCOUNT_FAIL,
	UPDATE_DELIVERY_INFO,
	UPDATE_PROFILE,
	UPDATE_DELIVERY_DETAILS_PROFILE,
	VERIFY_PHONE,
	GET_SINGLE_SETTING,
	GET_ALL_SETTING,
	GET_SHIPPING,
} from './types';

const initState = {
	profile: null,
	emailVerified: false,
	phoneVerified: false,
	checkoutPayment: null,
	settings: null,
	recipe: false,
	maxOrder: '',
	minOrder: '',
	threshold: '',
	overrideDefault: null,
	snackGlobalOverride: '',
	snackMinOrder: '',
	snackMaxOrder: '',
	snackThreshold: '',
	hospitalSettings: [],
	userMaxOrder: '',
	usertypeMinOrder: '',
	usertypeThreshold: '',
	has_collection: null,
	no_order_limit: '',
	shipping: null,
	shipping_fee_required:'',
	payment_setup: '',
	hospital_name: '',
	hospitalMealOverride: '',
	hospitalMealMinOrder: '',
	hospitalMealMaxOrder: '',
	hospitalSnackOverride: '',
	hospitalSnackMaxOrder: '',
	hospitalSnackMinOrder: '',
	hospital_settings: [],
	display_note:'',
	fetchAcc: false
};

function userReducer(state = initState, action) {
	let userProfile, account;
	switch (action.type) {
		case GET_ACCOUNT_SUCCESS:
			account = action.account;
			// console.log('account', account)
			userProfile = new UserProfile(
				account.first_name,
				account.last_name,
				account.email,
				account.phone_number,
				account.hospital,
				account.contact_preference,
				account.address_line_1,
				account.address_line_2,
				account.town_city,
				account.state_province,
				account.postal_code,
				account.email_verified,
				account.phone_number_verified
				// account.saleor_hospital.has_collection
			);

			return {
				...state,
				profile: {
					...state.profile,
					...userProfile,
				},
				has_collection: account.saleor_hospital.has_collection,
				emailVerified:
					typeof account.email_verified === 'boolean'
						? account.email_verified
						: account.email_verified === 'true',
				phoneVerified:
					typeof account.phone_number_verified === 'boolean'
						? account.phone_number_verified
						: account.phone_number_verified === 'true',
				hospital_settings: account.saleor_hospital?.settings,
				checkoutPayment: account.checkoutPayment,
				no_order_limit:account.saleor_hospital?.settings?.find(attr => attr.key === 'no_order_limit')?.value,
				shipping_fee_required:account.saleor_hospital?.settings?.find(attr => attr.key === 'shipping_fee_required')?.value,
				payment_setup:account.saleor_hospital?.settings?.find(attr => attr.key === 'payment')?.value,
				hospital_name:account.saleor_hospital?.hospital,
				hospitalMealOverride: account.saleor_hospital?.settings?.find(attr => attr.key === 'override_defaults')?.value,
				hospitalMealMinOrder: account.saleor_hospital?.settings?.find(attr => attr.key === 'min_order')?.value,
				hospitalMealMaxOrder: account.saleor_hospital?.settings?.find(attr => attr.key === 'max_order')?.value,
				hospitalSnackOverride: account.saleor_hospital?.settings?.find(attr => attr.key === 'snack_override_defaults')?.value,
				hospitalSnackMaxOrder: account.saleor_hospital?.settings?.find(attr => attr.key === 'snacks_max_order')?.value,
				hospitalSnackMinOrder: account.saleor_hospital?.settings?.find(attr => attr.key === 'snacks_min_order')?.value,
				display_note: account.saleor_hospital?.settings?.find(attr => attr.key === 'display_note')?.value,
				fetchAcc: true,
			};
		case GET_SINGLE_SETTING:
			return {
				...state,
				settings: action.settings,
				recipe: ToBool(
					action.settings.find((attr) => attr.setting_id === 'show_recipes')
						?.value
				),
			};
		case GET_ALL_SETTING:
			// console.log('userType_settings', action.userType_settings);
			return {
				...state,
				maxOrder: action.allSettings.find(
					(attr) => attr.setting_id === 'max_order'
				)?.value,
				minOrder: action.allSettings.find(
					(attr) => attr.setting_id === 'min_order'
				)?.value,
				threshold: action.allSettings.find(
					(attr) => attr.setting_id === 'threshold_amount'
				)?.value,
				overrideDefault:
					action.allSettings.find(
						(attr) => attr.setting_id === 'override_defaults'
					)?.value === 'true'
						? true
						: false,
				snackGlobalOverride:
					action.allSettings.find(
						(attr) => attr.setting_id === 'snack_override_defaults'
					)?.value === 'true'
						? true
						: false,
				snackMinOrder: action.allSettings.find(
					(attr) => attr.setting_id === 'snacks_min_order'
				)?.value,
				snackMaxOrder: action.allSettings.find(
					(attr) => attr.setting_id === 'snacks_max_order'
				)?.value,
				snackThreshold: action.allSettings.find(
					(attr) => attr.setting_id === 'snacks_threshold_amount'
				)?.value,
				hospitalSettings: action.allSettings,
			};
		case GET_ACCOUNT_FAIL:
			return initState;
		case UPDATE_DELIVERY_INFO:
			const info = action.deliveryInfo;
			userProfile = {
				firstName: info.first_name,
				lastName: info.last_name,
				phoneNumber: info.phone_number,
				streetLine1: info.address_line_1,
				streetLine2: info.address_line_2,
				city: info.town_city,
				state: info.state_province,
				zip: info.postal_code,
			};
			return {
				...state,
				profile: {
					...state.profile,
					...userProfile,
				},
			};
		case UPDATE_PROFILE:
			const profile = action.profile;
			userProfile = new UserProfile(
				profile.first_name,
				profile.last_name,
				state.profile.email,
				profile.phone_number,
				profile.hospital,
				profile.contact_preference,
				state.profile.streetLine1,
				state.profile.streetLine2,
				state.profile.city,
				state.profile.state,
				state.profile.zip,
				state.profile.checkoutPayment
			);

			return {
				...state,
				profile: {
					...state.profile,
					...userProfile,
				},
			};
		case UPDATE_DELIVERY_DETAILS_PROFILE:
			const deliveryProfile = action.deliveryDetailsProfile;
			userProfile = new UserProfile(
				state.profile.firstName,
				state.profile.lastName,
				state.profile.email,
				state.profile.phoneNumber,
				state.profile.hospital,
				state.profile.contactPreference,
				deliveryProfile.address_line_1,
				deliveryProfile.address_line_2,
				deliveryProfile.town_city,
				deliveryProfile.state_province,
				deliveryProfile.postal_code
			);
			return {
				...state,
				profile: {
					...state.profile,
					...userProfile,
				},
			};
		case CLEAR_PROFILE:
			return initState;
		case VERIFY_PHONE:
			return {
				...state,
				phoneVerified: true,
			};
		case GET_SHIPPING:
			return {
				...state,
				shipping: action.shipping
			};
		default:
			return state;
	}
}

export default userReducer;
