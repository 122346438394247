import { isEmpty, isNil } from 'lodash-es';

class Meal {
	constructor(meal, listName = 'meal-list') {
		this.id = meal.id;
		this.variantId = meal.variantId;
		this.name = meal.name;
		this.img = null;
		this.price = meal.price;

		// Process meal attributes
		let mealAttributes = {};
		meal.attributes.forEach((attr) => {
			mealAttributes = {
				...mealAttributes,
				...attr,
			};
		});
		this.featured = meal.metadata ? meal?.metadata.find(attr => attr.key === 'featured_key')?.value : ''
		this.allow_auth_display = meal.metadata ? meal?.metadata.find(attr => attr.key === 'allow_auth_display')?.value : ''
		this.tags = mealAttributes.meal_categories || [];
		this.color = mealAttributes.meal_color || 'default';
		this.hospital = mealAttributes.meal_hospital;
		this.productType = meal.productType;

		// Add feedback if modeling previous meals
		if (!isNil(meal.feedback)) {
			this.feedback = meal.feedback;
		}

		/**
		 * Switch img to use by type of meal listing.
		 * When there are multiple images, here's the legend by array element:
		 * [0] - Hero meal image
		 * [1] - vertical card meal image
		 * [2] - horizontal card meal image
		 */

		if (!isEmpty(meal.images)) {
			this.img = meal.images[0] && meal.images[0]['url'];

			switch (listName) {
				case 'featured-meals':
				case 'related-meals':
				case 'previous-meals':
					if (meal.images[1] && meal.images[1]['url']) {
						this.img = meal.images[1]['url'];
					}
					if (listName === 'previous-meals') {
						this.feedbackImgDesktop = meal.images[2] && meal.images[2]['url'];
						this.feedbackImgMobile = meal.images[0] && meal.images[0]['url'];
					}
					break;
				case 'meal-list':
					if (meal.images[2] && meal.images[2]['url']) {
						this.img = meal.images[2]['url'];
					}
					break;

				default:
					break;
			}
		}
	}
}

export default Meal;
