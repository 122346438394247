export const SUBMIT_ONBOARDING = 'SUBMIT_ONBOARDING',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  CLEAR_PROFILE = 'CLEAR_PROFILE',
  GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAIL = 'GET_ACCOUNT_FAIL',
  GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL',
  UPLOAD_PROFILE_PHOTO = 'UPLOAD_PROFILE_PHOTO',
  GET_USER_REWARDS_SUCCESS = 'GET_USER_REWARDS_SUCCESS',
  GET_USER_REWARDS_FAIL = 'GET_USER_REWARDS_FAIL',
  UPDATE_DELIVERY_INFO = 'UPDATE_DELIVERY_INFO',
  UPDATE_DELIVERY_DETAILS_PROFILE = 'UPDATE_DELIVERY_DETAILS_PROFILE',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  GET_NEW_CODE = 'GET_NEW_CODE',
  VERIFY_PHONE = 'VERIFY_PHONE',
  SEND_VERIFICATION_LINK = 'SEND_VERIFICATION_LINK',
  GET_SINGLE_SETTING = 'GET_SINGLE_SETTING',
  GET_ALL_SETTING = 'GET_ALL_SETTING',
  GET_SHIPPING = 'GET_SHIPPING';
