export const themeColors = () => {
  let rootStyle = getComputedStyle(document.body);
  return {
    primary: rootStyle.getPropertyValue("--primary").trim(),
    secondary: rootStyle.getPropertyValue("--secondary").trim(),
    inputBorderColor: rootStyle.getPropertyValue("--input-border-color").trim(),
    darkGray: rootStyle.getPropertyValue("--dark-gray").trim(),
    gray: rootStyle.getPropertyValue("--gray").trim(),
    yellow: rootStyle.getPropertyValue("--yellow").trim(),
    rust: rootStyle.getPropertyValue("--rust").trim(),
    retroBlue: rootStyle.getPropertyValue("--retro-blue").trim(),
    rustDis: rootStyle.getPropertyValue("--rust-dis").trim(),
  };
};
