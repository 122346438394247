import { findIndex, map, uniq } from 'lodash-es';
import FullMeal from '../../models/full-meal';
import Meal from '../../models/meal';
import {
	ADD_MEALS_ON_FEEDBACK,
	CLEAR_USER_MEALS,
	FETCH_FEATURED_MEALS,
	FETCH_MEAL,
	FETCH_RELATED_MEALS,
	GET_MEAL_FEEDBACK,
	GET_MEAL_LIST,
	GET_PAST_MEALS_START,
	GET_PAST_MEALS_END,
	GIVE_MEAL_FEEDBACK,
	MEAL_FEEDBACK_FINISH,
	SKIP_MEAL_FEEDBACK,
	GET_FEATURED_MEAL,
	GET_FAVORITE_MEALS,
	TOGGLE_MEAL_IS_FAVORITE,
	GET_MEAL_LIST_BY_HOSPITAL,
	GET_SNACK_LIST_BY_HOSPITAL,
	GET_SNACK_LIST,
} from './types';

const initState = {
	listHospital: [],
	allMeals: [],
	list: [],
	snackList: [],
	listSize: 12,
	snackListSize: 12,
	listHasMore: true,
	hasMoreSnacks: true,
	data: {},
	past: [],
	pastFetched: false,
	feedback: [],
	featured: [],
	fetchedFeatured: false,
	related: {},
	userMealFeedback: {},
	featuredMeal: null,
	featuredMealFetched: false,
	favorites: [],
	favoritesFetched: false,
	display_price: '',
	userTypeSettings: [],
	usertypeMaxOrder: '',
	usertypeMinOrder: '',
	usertypeThreshold: '',
};

function mealReducer(state = initState, action) {
	switch (action.type) {
		case 'GET_ALL_MEALS':
			return {
				...state,
				list: action.meals.map((meal) => new Meal(meal)).filter(meal => meal.allow_auth_display === 'true' || meal.allow_auth_display === undefined),
			};
		case GET_MEAL_LIST:
			return {
				...state,
				list: action.meals.map((meal) => new Meal(meal)).filter(meal => meal.allow_auth_display === 'true' || meal.allow_auth_display === undefined),
				listHasMore: !!action.hasMore,
				listSize: state.listSize + 12,
			};
		case GET_SNACK_LIST:
			console.log(action)
			return {
				...state,
				snackList: action.snacks.map((meal) => new Meal(meal)),
				hasMoreSnacks: !!action.hasMoreSnack,
				snackListSize: state.snackListSize + 6,
			};
		case GET_MEAL_LIST_BY_HOSPITAL:
			return {
				...state,
				list: action.meals.map((meal) => new Meal(meal)),
				listHospital: action.meals.map((meal) => new Meal(meal)),
				listHasMore: !!action.hasMore,
				listSize: state.listSize + 12,
				display_price: action.display_price,
				userTypeSettings: action.userType_settings,
				usertypeMaxOrder: action.userType_settings.find(
					(attr) => attr.key === 'max_order'
				)?.value,
				usertypeMinOrder: action.userType_settings.find(
					(attr) => attr.key === 'min_order'
				)?.value,
				usertypeThreshold: action.userType_settings.find(
					(attr) => attr.key === 'threshold_amount'
				)?.value,
			};

		case GET_SNACK_LIST_BY_HOSPITAL:
			return {
				...state,
				snackList: action.snacks.map((meal) => new Meal(meal)),
				// listHospital: action.meals.map((meal) => new Meal(meal)),
				hasMoreSnacks: !!action.hasMoreSnack,
				snackListSize: state.snackListSize + 6,
			};

		case GET_PAST_MEALS_START:
			return {
				...state,
				pastFetched: false,
			};
		case GET_PAST_MEALS_END:
			return {
				...state,
				past: action.meals.map((meal) => new Meal(meal, 'previous-meals')),
				pastFetched: true,
			};
		case ADD_MEALS_ON_FEEDBACK:
			return {
				...state,
				feedback: uniq(state.feedback.concat(action.meals)),
			};
		case MEAL_FEEDBACK_FINISH:
			return {
				...state,
				feedback: [],
			};
		case CLEAR_USER_MEALS:
			return {
				...state,
				past: [],
				pastFetched: false,
				feedback: [],
				userMealFeedback: {},
				favorites: [],
				favoritesFetched: false,
			};
		case FETCH_MEAL:
			// console.log('action.meal', action.meal)

			return {
				...state,
				data: {
					...state.data,
					[action.meal.id]: new FullMeal(
						{
							...action.meal,
							ingredients: action.ingredients,
							attributes: action.attributes,
							metadata: action.metadata,
						},
						'meal-details'
					),
				},
			};
		case FETCH_FEATURED_MEALS:
			return {
				...state,
				featured: action.featuredMeals.map(
					(meal) => new Meal(meal, 'featured-meals')
				),
				fetchedFeatured: true,
			};
		case FETCH_RELATED_MEALS:
			return {
				...state,
				related: {
					...state.related,
					[action.mealId]: {
						list: action.relatedMeals.map(
							(meal) => new Meal(meal, 'related-meals')
						),
						fetched: true,
					},
				},
			};
		case GIVE_MEAL_FEEDBACK:
			const mealIdx = findIndex(state.past, ['id', action.mealId]);
			const updatedPastMeals = [...state.past];
			updatedPastMeals[mealIdx] = {
				...updatedPastMeals[mealIdx],
				feedback: true,
			};

			return {
				...state,
				past: updatedPastMeals,
				feedback: state.feedback.filter((meal) => meal !== action.mealId),
			};
		case SKIP_MEAL_FEEDBACK:
			return {
				...state,
				feedback: state.feedback.filter((meal) => meal !== action.mealId),
			};
		case GET_MEAL_FEEDBACK:
			return {
				...state,
				userMealFeedback: {
					...state.userMealFeedback,
					[action.mealId]: action.mealFeedbackData,
				},
			};
		case GET_FEATURED_MEAL:
			return {
				...state,
				featuredMeal: new FullMeal(action.meal),
				featuredMealFetched: true,
			};
		case GET_FAVORITE_MEALS:
			return {
				...state,
				favorites: map(action.meals, 'id'),
				favoritesFetched: true,
			};
		case TOGGLE_MEAL_IS_FAVORITE:
			return {
				...state,
				favorites: action.toggleVal
					? uniq(state.favorites.concat(action.meal))
					: state.favorites.filter((meal) => meal !== action.meal),
			};
		default:
			return state;
	}
}

export default mealReducer;
