import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const PublicRoute = ({ component: Component, home, ...routeProps }) => {
  const { authenticated, onboarding } = useSelector(({ auth, user }) => ({
    authenticated: auth.isLoggedIn,
    onboarding: user.onboarding,
  }));

  if(routeProps.location.search) window.localStorage.setItem('utms', routeProps.location.search)

  const urlParams = new URLSearchParams(window.localStorage.getItem('utms'));
  
  const utm_medium = urlParams.get('utm_medium')
  const utm_source = urlParams.get('utm_source')
  const utm_campaign = urlParams.get('utm_campaign')

  window.localStorage.setItem('utm_medium', utm_medium)
  window.localStorage.setItem('utm_source', utm_source)
  window.localStorage.setItem('utm_campaign', utm_campaign)

  useEffect(() => {
    if(authenticated) {
        window.localStorage.removeItem('utm_medium')
        window.localStorage.removeItem('utm_source')
        window.localStorage.removeItem('utm_campaign')
        window.localStorage.removeItem('utms')
    }
  },[authenticated])

  return (
    <Route
      {...routeProps}
      render={(props) =>
        authenticated && onboarding && !onboarding.onboarded ? (
          <Redirect
            to={{
              pathname: "/onboarding",
              state: {
                from: props.location,
              },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
