import { Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";
import "./assets/scss/theme.scss";
import ScrollToTop from "./components/routes/ScrollToTop";
import AppRouteSwitcher from "./routes/AppRouteSwitcher";
// import QueryPersist from "./routes/QueryPersist";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        {/* <QueryPersist /> */}
        <Suspense fallback={<div className="loading" />}>
          <Route render={(props) => <AppRouteSwitcher {...props} />} />
        </Suspense>
        <ToastContainer autoClose={3000} />
      </BrowserRouter>
    </div>
  );
}

export default App;
