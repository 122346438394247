import userAxios from './user-axios';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const AUTH_API_URL = API_URL + '/auth';

const ADMIN_URL = process.env.REACT_APP_ADMIN_API_BASE_URL;
const SETTINGS_API_URL = ADMIN_URL + '/settings';

const SHIPPING_API_URL = ADMIN_URL + '/shipping';

export const getAccount = async (email) => {
	return await userAxios.get(`${AUTH_API_URL}/account/${email}`);
};

export const sendEmailVerification = async () => {
	return await userAxios.get(`${AUTH_API_URL}/getEmailVerificationCode`);
};

export const editUser = async (email, profileData) => {
	return await userAxios.post(`${AUTH_API_URL}/editUser/${email}`, profileData);
};

export const changePassword = async (passwordData) => {
	return await userAxios.post(`${AUTH_API_URL}/changePassword/`, passwordData);
};

export const getPhoneVerificationCode = async () => {
	return await userAxios.get(`${AUTH_API_URL}/getPhoneVerificationCode`);
};

export const verifyPhone = async (code) => {
	return await userAxios.post(`${AUTH_API_URL}/verifyPhone`, {
		code,
	});
};

export const getSingleSetting = async () => {
	const obj = {
		setting_id: 'show_recipes',
	};

	return await axios.post(`${SETTINGS_API_URL}/single`, obj);
};

export const getAllSetting = async () => {
	return await userAxios.get(`${SETTINGS_API_URL}/all`);
};

export const getShipping = async () => {
	return await userAxios.get(`${SHIPPING_API_URL}/getShipping`);
};
