import React from "react";
import Select, { components } from "react-select";
import { useField } from "formik";
import { Form } from "react-bootstrap";

import { ReactComponent as DropDownIcon } from "../../assets/img/icons/select-dropdown.svg";
import { themeColors } from "../../helpers/themeColors";

// Assets
import '../../assets/scss/components/react-select.scss'

const colors = themeColors();

const customStyles = {
  input: (styles) => ({ ...styles, padding: 0, margin: 0, lineHeight: "25px" }),
  control: (styles, state) => {
    const customStyles = {
      ...styles,
      border: "2px solid #F2F4F2",
    }
    if (state.isDisabled) {
      customStyles.background = "white"
    }
    return customStyles 
  },
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: "26px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: "#F2F4F2",
    width: "2px",
    display: "none",
  }),
  dropdownIndicator: (styles, state) => {
    const custom = {
      ...styles,
      fontSize: "24px",
      padding: "1px 14px",
      ".icon": {
        path: {
          fill: colors.darkGray,
          transition: "fill 150ms"
        },
      },
    }
    if (state.isDisabled) {
      custom.display = "none"
    }

    return custom
  },
  singleValue: (styles, state) => {
    const custom = styles
    if (state.isDisabled) {
      custom.color = colors.darkGray
    }
    return custom
  },
  placeholder: (styles, state) => {
    const custom = styles
    if (state.isDisabled) {
      custom.color = colors.darkGray
    }
    return custom
  },
  menu: (styles) => ({
    ...styles,
    zIndex: 2,
  }),
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <DropDownIcon className="icon" />
  </components.DropdownIndicator>
);

const FormikReactSelect = (props) => {
  const [field, meta, helpers] = useField(props);

  const invalidClassname = () =>
    meta.touched && meta.error ? "is-invalid" : "";

  const handleChange = (value) => {
    helpers.setValue(value);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <Form.Group id={`form-group-${props.name}`} className={props.formGroupClasses}>
      <Form.Label className={props.labelClassname || ""}>{props.label}</Form.Label>
      <Select
        className={`react-select ${
          props.className || ""
        } ${invalidClassname()}`}
        classNamePrefix="react-select"
        options={props.options}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        isLoading={props.isLoading}
        placeholder={<span style={{fontSize: '12px'}}>{props.placeholder || "Select..."}</span>}
        isDisabled={!!props.isDisabled}
        styles={customStyles}
        components={{ DropdownIndicator }}
        onChange={handleChange}
        onBlur={handleBlur}
        value={field.value}
        // menuIsOpen={true}
        {...props.selectProps}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger mt-1 small">{meta.error}</div>
      ) : null}
    </Form.Group>
  );
};

export default FormikReactSelect;
