import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";

const useIntercomUserUpdate = () => {
  const { authenticated, profile } = useSelector(({ auth, user }) => ({
    authenticated: auth.isLoggedIn,
    profile: user && user.profile,
  }));

  const { shutdown, boot } = useIntercom();

  useEffect(() => {
    if (authenticated && profile && profile.firstName) {
      shutdown();
      boot({
        name: `${profile.firstName} ${profile.lastName}`,
        email: profile.email
      });
    } else if (!authenticated && !profile) {
      shutdown();
      boot();
    }
  }, [authenticated, profile, shutdown, boot]);

  return;
};

export default useIntercomUserUpdate;
