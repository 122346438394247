import { formatDistanceToNow } from "date-fns/esm";
import { isEmpty } from "lodash-es";

class Blog {
    constructor (blog) {
        this.id = blog.id;
        this.title = blog.contentText;
        this.img = blog.image && !isEmpty(blog.image) && blog.image[0]["url"];
        this.link = blog.facebookUrl;
        this.postDate = formatDistanceToNow(new Date(blog.blogDatetime), { addSuffix: true })
    }
}

export default Blog