export const ADD_TO_CART = 'ADD_TO_CART',
  REMOVE_FROM_CART = 'REMOVE_FROM_CART',
  ADD_QTY = 'ADD_QTY',
  SUBTRACT_QTY = 'SUBTRACT_QTY',
  CLEAR_CART = 'CLEAR_CART',
  GET_CART = 'GET_CART',
  SET_PROMO_CODE_VALIDITY = 'SET_PROMO_CODE_VALIDITY',
  CREATE_CHECKOUT_REQUEST = 'CREATE_CHECKOUT_REQUEST',
  CREATE_CHECKOUT_SUCCESS = 'CREATE_CHECKOUT_SUCCESS',
  CREATE_CHECKOUT_FAILURE = 'CREATE_CHECKOUT_FAILURE'

