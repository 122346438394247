import { isEmpty, map, sample } from 'lodash-es';

class FullMeal {
	constructor(meal, pageName = null) {
		this.id = meal.id;
		this.variantId = meal.variantId;
		this.name = meal.name;
		this.thumbnail = meal.thumbnail ? meal.thumbnail.url : null;
		this.img =
			meal.images && !isEmpty(meal.images) && meal.images[0]
				? meal.images[0].url
				: null;
		this.images = meal.images;
		this.description = meal.description;
		this.metadata = meal.metadata

		// Process meal attributes
		let mealAttributes = {};
		meal.attributes.forEach((attr) => {
			mealAttributes = {
				...mealAttributes,
				...attr,
			};
		});

		this.tags = mealAttributes.meal_categories || [];
		this.color =
			mealAttributes.meal_color || sample(['yellow', 'rust', 'retro-blue']);
		this.hospital = mealAttributes.meal_hospital;
		this.featuredImgs = {
			aboutWeb: mealAttributes.featured_meal_img_about,
			aboutTablet: mealAttributes.featured_meal_img_about_tab,
			aboutPhone: mealAttributes.featured_meal_img_about_phone,
			authWeb: mealAttributes.featured_meal_img_auth,
			authMobile: mealAttributes.featured_meal_img_auth_mobile,
		};
		this.price = meal.prices[0] ? meal.prices[0]?.price?.amount : 0;
		this.productType = meal?.productType

		if (pageName === 'meal-details') {
			// console.log(mealAttributes)
			// Packaged Meal Ingredients
			this.ingredients = map(meal.ingredients, (ing) => ({
				...ing,
				photo: ing.images && !isEmpty(ing.images) ? ing.images[0]['url'] : null,
			}));

			// Recipe Ingredients
			this.recipeIngredients = {
				packagedMeal: mealAttributes.pack_ingredients || [],
				fromScratch: mealAttributes.ingredients_from_scratch || [],
			};

			// Recipe Directions
			this.recipeDirections = {
				packagedMeal: mealAttributes.meal_directions || [],
				fromScratch: mealAttributes.meal_from_scratch || [],
			};

			// Meal facts - prep time, cook time, yield
			this.mealFacts = {
				packagedMeal: {
					cookTime: mealAttributes.pack_cook,
					prepTime: mealAttributes.pack_prep,
					yield: mealAttributes.pack_yield,
				},
				fromScratch: {
					cookTime: mealAttributes.scratch_cook,
					prepTime: mealAttributes.scratch_prep,
					yield: mealAttributes.scratch_yield,
				},
			};

			// Nutrition facts
			this.nutritionFacts = mealAttributes.nutrition_fact_items;
		}
	}
}

export default FullMeal;
