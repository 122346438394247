class UserProfile {
  constructor(
    firstName,
    lastName,
    email,
    phoneNumber,
    hospital,
    contactPreference,
    streetLine1,
    streetLine2,
    city,
    state,
    zip,
    checkoutPayment,
  ) {
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.phoneNumber = phoneNumber
    this.hospital = hospital
    this.contactPreference = contactPreference
    this.streetLine1 = streetLine1
    this.streetLine2 = streetLine2
    this.city = city
    this.state = state
    this.zip = zip
    this.checkoutPayment = checkoutPayment
  }
}

export default UserProfile
