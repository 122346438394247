import { format, getMonth, getYear } from 'date-fns';
import compact from 'lodash/compact';

export const joinClasses = (classNames) => {
	if (!Array.isArray(classNames)) {
		return '';
	}
	return compact(classNames).join(' ');
};

export function extractErrorMsg(error) {
	// Sometimes error messages are in object or string
	// This will extract the message in both case
	if (typeof error === 'object') {
		return error.message;
	} else if (typeof error === 'string') {
		return error;
	} else {
		return 'Something went wrong.';
	}
}

export const asyncForEach = async (array, callback) => {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
};

export const anonArray = (length) => Array.from(Array(length).keys());

export const dateRangeDisplayer = (start = 0, end = 0) => {
	if (getYear(start) !== getYear(end)) {
		return `${format(start, 'PP')} - ${format(end, 'PP')}`;
	} else if (getMonth(start) !== getMonth(end)) {
		return `${format(start, 'MMM d')} - ${format(end, 'MMM d')}, ${format(
			end,
			'yyyy'
		)}`;
	} else {
		return `${format(start, 'MMMM')} ${format(start, 'd')} - ${format(
			end,
			'd'
		)}, ${format(end, 'yyyy')}`;
	}
};

export const hexToRgb = (hex) => {
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function (m, r, g, b) {
		return r + r + g + g + b + b;
	});

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: {
				r: 255,
				g: 255,
				b: 255,
		  };
};

export function createMarkup(markup) {
	return { __html: markup };
}

export const getUrl = (location, param) => {
	const url = new URLSearchParams(location)?.get(param)?.toString();

	if (param === 'email') {
		return url?.replace(/ /g, '+');
	} else {
		return decodeURI(url);
	}
};

export const ToBool = (data) =>
	data === 'no' || data === 'false' ? false : true;

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						// reject('maximum retries exceeded');
						reject(error);
						return;
					}

					// Passing on "reject" is the important part
					retry(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
};

export function NumberFormatter(num) {
	if (num === null || num === '' || num === undefined) {
		return num; 
	} else  {
		num = parseFloat(num).toFixed(2);
		return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
}
