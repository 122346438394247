import Order from '../../models/order'
import {
  ADD_ORDER,
  CLEAR_ORDERS,
  GET_ORDERS,
  GET_WEEKLY_ORDER_STATUS,
} from './types'

const initialState = {
  list: [],
  data: {},
  checkedWeeklyOrderStatus: false,
  daysBeforeCutoff: 0,
  hasOrderedThisWeek: false,
  details: {},
}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ORDER_DETAILS':
      return {
        ...state,
        details: action.details,
      }
    case ADD_ORDER:
      const { id, items, date, instructions, deliveryDetails, etd, status, discount, promoCode } =
        action.orderData

      const newOrder = new Order(
        id,
        items,
        deliveryDetails,
        date,
        etd,
        instructions,
        status,
        discount,
        promoCode,
      )

      return {
        ...state,
        list: state.list.concat(newOrder),
        data: {
          ...state.data,
          [newOrder.id]: newOrder,
        },
        hasOrderedThisWeek: true,
      }

    case GET_ORDERS:
      return {
        orders: action.orders,
      }

    case CLEAR_ORDERS:
      return initialState

    case GET_WEEKLY_ORDER_STATUS:
      return {
        ...state,
        checkedWeeklyOrderStatus: true,
        daysBeforeCutoff: action.status && action.status.daysBeforeCutoff,
        hasOrderedThisWeek: action.status && action.status.ordered,
      }

    default:
      return state
  }
}

export default orderReducer
