import axios from "axios";
import store from "../store";
import * as authActions from "../store/auth/actions";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const userAxios = axios.create({
  baseURL: API_URL,
});

userAxios.interceptors.request.use(
  async (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.accessToken) {
      config.headers["x-access-token"] = user.accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

userAxios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const errorData = error.response.data;
    if (
      errorData &&
      errorData.success === false &&
      errorData.name === "TokenExpiredError"
    ) {
      console.log("Token has expired. Refreshing token.");
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && !user.refreshToken) {
          throw new Error("no-refresh-token");
        }
        const response = await fetch(
          `${API_URL}/auth/refreshToken/${user.username}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              refreshToken: user.refreshToken,
            }),
          }
        );
        if (response.status >= 200 && response.status < 300) {
          const newTokens = await response.json();
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...newTokens,
              username: user.username,
            })
          );
          originalRequest._retry = true;
          originalRequest.headers["x-access-token"] = newTokens.accessToken;
          console.log(
            "Token has been refreshed. Retrying your previous request now..."
          );

          return axios(originalRequest);
        } else {
          const data = await response.json();
          throw data.error;
        }
      } catch (refreshTokenError) {
        if (
          refreshTokenError &&
          refreshTokenError.message === "no-refresh-token"
        ) {
          return store.dispatch(authActions.logout());
        }
        return Promise.reject(refreshTokenError);
      }
    }
    else if (
      errorData &&
      errorData.success === false &&
      (errorData.message === "Not a valid JWT token" || errorData.message === "Invalid Token.")
    ) {
      return store.dispatch(authActions.logout());
    }

    return Promise.reject(error);
  }
);

export default userAxios;
