import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const UserRoute = ({ component: Component, componentProps, ...routeProps }) => {
  const authenticated = useSelector(({ auth }) => auth.isLoggedIn);
  return (
    <Route
      {...routeProps}
      render={(props) =>
        authenticated ? (
          <Component {...props} {...componentProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export default UserRoute;
