import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import userReducer from "./user/reducer";
import cartReducer from "./cart/reducer";
import orderReducer from "./order/reducer";
import mealReducer from "./meal/reducer";
import blogReducer from "./blog/reducer";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  cart: cartReducer,
  order: orderReducer,
  meal: mealReducer,
  blog: blogReducer
});
