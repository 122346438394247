class CartItem {
	constructor(
		quantity,
		img,
		name,
		tags,
		id,
		attributes = null,
		price,
		productType,
		featured,
		variantId,
	) {
		this.quantity = quantity;
		this.mealPicture = img;
		this.mealName = name;
		this.id = id;
		this.price = price !== undefined ? price : 0;
		this.featured = featured;

		if (!tags && !!attributes) {
			// Process meal attributes
			let mealAttributes = {};
			attributes.forEach((attr) => {
				mealAttributes = {
					...mealAttributes,
					...attr,
				};
			});
			this.tags = mealAttributes.meal_categories || [];
		} else this.tags = tags;

		this.productType = productType;
		this.variantId = variantId
	}
}
export default CartItem;
