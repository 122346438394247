import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  GET_HOSPITAL_LIST,
  GET_HOSPITAL_LIST_ERROR,
} from './types'

const user = JSON.parse(localStorage.getItem('user'))

const initialState = user
  ? {
      isLoggedIn: true,
      user,
      hospitals: [],
      fetched: false,
    }
  : { isLoggedIn: false, user: null, hospitals: [], fetched: false }

function authReducer(state = initialState, action) {
  const { type, payload, data } = action

  switch (type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      }
    case SIGNUP_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      }
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        loggedOutManually: action.loggedOutManually,
      }
    case REQUEST_RESET_PASSWORD_SUCCESS:
    case REQUEST_RESET_PASSWORD_FAIL:
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAIL:
    case GET_HOSPITAL_LIST_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }

    case GET_HOSPITAL_LIST:
      return {
        ...state,
        hospitals: data.map((hospital) => ({
          ...hospital,
          value: hospital.id,
          label: hospital.name,
        })),
        fetched: true,
      }
    default:
      return state
  }
}

export default authReducer
