import Blog from "../../models/blog";
import { GET_BLOGS } from "./types";

const initialState = {
  list: [],
  listSize: 8,
  listHasMore: true,
  fetched: false
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOGS:
      return {
        ...state,
        list: action.blogs.map(blog => new Blog(blog)),
        listHasMore: action.blogs.length >= state.listSize,
        listSize: state.listSize + 4,
        fetched: !action.blogs.length >= state.listSize
      }

    default:
      return state;
  }
};

export default blogReducer;
