import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const AUTH_API_URL = API_URL + '/auth';

export const signup = async (signupData) => {
	return await axios.post(AUTH_API_URL + '/signup', signupData);
};

export const login = async ({ email, password, keep }) => {
	return await axios.post(AUTH_API_URL + '/signin', {
		email,
		password,
		keep,
	});
};

export const requestReset = async (email) => {
	return await axios.post(`${AUTH_API_URL}/forgotPassword/${email}`);
};

export const resetPassword = async ({ email, verificationCode, password }) => {
	return await axios.post(`${AUTH_API_URL}/confirmForgotPassword/${email}`, {
		verificationCode,
		password,
	});
};

export const sendEmailVerificationOnSignup = async (token) => {
	return await axios.get(`${AUTH_API_URL}/getEmailVerificationCode`, {
		headers: {
			'x-access-token': token,
		},
	});
};

export const getPhoneVerificationCodeOnSignup = async (token) => {
	return await axios.get(`${AUTH_API_URL}/getPhoneVerificationCode`, {
		headers: {
			'x-access-token': token,
		},
	});
};

export const verifyPhoneOnSignup = async (code, token) => {
	return await axios.post(
		`${AUTH_API_URL}/verifyPhone`,
		{
			code,
		},
		{
			headers: {
				'x-access-token': token,
			},
		}
	);
};

export const getListHospitals = async () => {
	return await axios.get(`${AUTH_API_URL}/listhospitals`);
};

export const setNewAdminPassword = async (creds) => {
	return await axios.post(`${AUTH_API_URL}/setNewAdminPassword`, creds);
};

export const submitMessage = async (creds) => {
	return await axios.post(`${API_URL}/submitMessage`, creds);
};

export const submitNewsletter = async (email) => {
	return await axios.post(`${API_URL}/submitNewsletter`, email);
};
