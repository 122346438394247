export const GET_MEAL_LIST = 'GET_MEAL_LIST',
  GET_PAST_MEALS_START = 'GET_PAST_MEALS_START',
  GET_PAST_MEALS_END = 'GET_PAST_MEALS_END',
  ADD_PAST_MEALS = 'ADD_PAST_MEALS',
  ADD_MEALS_ON_FEEDBACK = 'ADD_MEALS_ON_FEEDBACK',
  MEAL_FEEDBACK_FINISH = 'MEAL_FEEDBACK_FINISH',
  CLEAR_USER_MEALS = 'CLEAR_USER_MEALS',
  FETCH_MEAL = 'FETCH_MEAL',
  FETCH_FEATURED_MEALS = 'FETCH_FEATURED_MEALS',
  FETCH_RELATED_MEALS = 'FETCH_RELATED_MEALS',
  GIVE_MEAL_FEEDBACK = 'GIVE_MEAL_FEEDBACK',
  SKIP_MEAL_FEEDBACK = 'SKIP_MEAL_FEEDBACK',
  GET_MEAL_FEEDBACK = 'GET_MEAL_FEEDBACK',
  GET_FEATURED_MEAL = 'GET_FEATURED_MEAL',
  TOGGLE_MEAL_IS_FAVORITE = 'TOGGLE_MEAL_IS_FAVORITE',
  GET_FAVORITE_MEALS = 'GET_FAVORITE_MEALS',
  POST_GENERAL_FEEDBACK = 'POST_GENERAL_FEEDBACK',
  GET_MEAL_LIST_BY_HOSPITAL = 'GET_MEAL_LIST_BY_HOSPITAL',
  GET_SNACK_LIST_BY_HOSPITAL = 'GET_SNACK_LIST_BY_HOSPITAL',
  GET_SNACK_LIST = 'GET_SNACK_LIST'
