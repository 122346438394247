import React, { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { retry } from '../helpers/utils';

import useIntercomUserUpdate from '../hooks/useIntercomUserUpdate';
import * as userActions from '../store/user/actions';

// Routes
import AuthRoute from './AuthRoute';
import PublicRoute from './PublicRoute';
import UserRoute from './UserRoute';

// Auth pages
const SignInPage = lazy(() => retry(() => import('../pages/auth/SignIn')));
const SignUpPage = lazy(() => retry(() => import('../pages/auth/SignUp')));
const ActivateAccountPage = lazy(() =>
  retry(() => import('../pages/auth/ActivateAccount'))
);
const ForgotPasswordPage = lazy(() =>
  retry(() => import('../pages/auth/ForgotPassword'))
);
const ChangePasswordPage = lazy(() => import('../pages/auth/ResetPassword'));
const NewCredPage = lazy(() =>
  retry(() => import('../pages/auth/NewCredentials'))
);

// Common to visitors and users
// const LandingPage = lazy(() => import('../pages/common/Landing'))
const HospitalPage = lazy(() =>
  retry(() => import('../pages/common/Hospital'))
);
const NewLandingPage = lazy(() =>
  retry(() => import('../pages/common/Homepage'))
);
// const NewLandingPage = lazy(() => import('../pages/common/NewLanding'))
// const ParticipantPage = lazy(() => retry(() => import('../pages/common/Participants')))
const ParticipantPage = lazy(() =>
  retry(() => import('../pages/common/NewParticipants'))
);

const AboutPage = lazy(() => retry(() => import('../pages/common/NewAboutUs')));
const MealPage = lazy(() => retry(() => import('../pages/common/Meal')));
const MealListPage = lazy(() => retry(() => import('../pages/common/Meals')));
const TermsPage = lazy(() =>
  retry(() => import('../pages/common/TermsAndConditions'))
);

const PrivacyPage = lazy(() => import('../pages/common/PrivacyPolicy'));
const NotFoundPage = lazy(() => import('../pages/common/NotFound'));
const PressPage = lazy(() => import('../pages/common/Press'));
const FAQPage = lazy(() => retry(() => import('../pages/common/FAQ')));

// User pages
const newCartPage = lazy(() => retry(() => import('../pages/user/NewCart')));
const NewCheckoutPage = lazy(() =>
  retry(() => import('../pages/user/NewCheckout'))
);
const OrderSummaryPage = lazy(() =>
  retry(() => import('../pages/user/OrderSummary'))
);
const FeedbackSectionPage = lazy(() =>
  retry(() => import('../pages/user/FeedbackSection'))
);

const ProfilePage = lazy(() => retry(() => import('../pages/user/Profile')));
const ActivateAccountCheckoutPage = lazy(() =>
  import('../pages/user/ActivateAccountCheckout')
);

function AppRouteSwitcher() {
  const authenticated = useSelector(({ auth }) => auth.isLoggedIn);

  useIntercomUserUpdate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (authenticated) {
      dispatch(userActions.getAccount());
    } else {
      window.FS.anonymize();
    }
  }, [authenticated, dispatch]);

  // useEffect(() => {
  //   console.log('hasCollection', hasCollection)
  //   if (!hasCollection) {
  //     history.push('/profile')
  //   } else return null
  // }, [hasCollection, history])

  return (
    <Switch>
      <PublicRoute path='/participants' exact component={ParticipantPage} />
      {/* <PublicRoute path="/hospital" exact component={LandingPage} /> */}
      <PublicRoute path='/hospital' exact component={HospitalPage} />
      <PublicRoute path='/faq' exact component={FAQPage} />
      <PublicRoute path='/' exact component={NewLandingPage} />
      <PublicRoute path='/set-user-credentials' exact component={NewCredPage} />
      <PublicRoute path='/about' exact component={AboutPage} />
      <PublicRoute path='/404' exact component={NotFoundPage} />
      <PublicRoute path='/terms-and-conditions' exact component={TermsPage} />
      <PublicRoute path='/privacy-policy' exact component={PrivacyPage} />
      <PublicRoute path='/resources' exact component={PressPage} />
      <PublicRoute path='/meals' exact component={MealListPage} />
      <PublicRoute path='/meal/:mealId' component={MealPage} />
      <AuthRoute
        path='/activate-account'
        exact
        component={ActivateAccountPage}
      />
      <AuthRoute path='/forgot-password' exact component={ForgotPasswordPage} />
      <AuthRoute path='/reset-password' exact component={ChangePasswordPage} />
      <UserRoute path='/cart' exact component={newCartPage} />

      {/* <UserRoute path="/cart" exact component={CartPage} /> */}
      <UserRoute path='/feedback' exact component={FeedbackSectionPage} />
      <UserRoute path='/checkout' exact component={NewCheckoutPage} />
      <UserRoute path='/order-summary/:orderId' component={OrderSummaryPage} />
      <UserRoute path='/profile' component={ProfilePage} />
      <UserRoute
        path='/checkout-activate-account'
        component={ActivateAccountCheckoutPage}
      />
      <Redirect to='/404' />
    </Switch>
  );
}

export default AppRouteSwitcher;
