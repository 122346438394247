// import { startOnboarding } from "../user/actions";
// import dummy from "../../data/dummy-user";
// import { getUserAccount } from "../user/actions";
import {
	SIGNUP_SUCCESS,
	SIGNUP_FAIL,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	REQUEST_RESET_PASSWORD_SUCCESS,
	REQUEST_RESET_PASSWORD_FAIL,
	VERIFY_PHONE,
	GET_NEW_CODE,
	SEND_VERIFICATION_LINK,
	GET_HOSPITAL_LIST,
	GET_HOSPITAL_LIST_ERROR,
	NEW_ADMIN_PASSWORD,
	NEW_ADMIN_PASSWORD_ERROR,
	SUBMIT_MESSAGE,
} from './types';
// import { GET_ACCOUNT_FAIL } from "../user/types";

import * as authService from '../../services/auth.service';
import { CLEAR_CART } from '../cart/types';
import { CLEAR_USER_MEALS } from '../meal/types';
import { CLEAR_ORDERS } from '../order/types';
import { CLEAR_PROFILE } from '../user/types';

export const login = (credentials) => async (dispatch) => {
	try {
		const response = await authService.login(credentials);
		if (response.status >= 200 && response.status < 300) {
			const { data } = response;
			if (data.accessToken) {
				localStorage.setItem('user', JSON.stringify(data));
			}
			dispatch({
				type: LOGIN_SUCCESS,
				payload: {
					user: data,
				},
			});
		}
	} catch (error) {
		dispatch({ type: LOGIN_FAIL });
		const message = error.response && error.response.data;
		throw message;
	}
};

export const signup = (signupData) => async (dispatch) => {
	try {
		const response = await authService.signup(signupData);
		if (response.status >= 200 && response.status < 300) {
			const data = response.data;
			if (!data.success) {
				throw new Error('Sign up failed, something went wrong!');
			}
			dispatch({ type: SIGNUP_SUCCESS });
			return data;
		}
	} catch (error) {
		dispatch({ type: SIGNUP_FAIL });
		const message = error.response && error.response.data;
		throw message;
	}
};

export const logout =
	(loggedOutManually = false) =>
		(dispatch) => {
			localStorage.removeItem('user');
			localStorage.removeItem('meal');
			localStorage.removeItem('hospital_name');
			localStorage.removeItem('allSettings');
			localStorage.removeItem('checkout_id')

			dispatch({
				type: LOGOUT,
				loggedOutManually,
			});
			dispatch({ type: CLEAR_CART });
			dispatch({ type: CLEAR_USER_MEALS });
			dispatch({ type: CLEAR_ORDERS });
			dispatch({ type: CLEAR_PROFILE });
		};

export const requestResetPassword = (email) => async (dispatch) => {
	try {
		const response = await authService.requestReset(email);
		if (response.status >= 200 && response.status < 300) {
			dispatch({ type: REQUEST_RESET_PASSWORD_SUCCESS });
		}
	} catch (error) {
		dispatch({ type: REQUEST_RESET_PASSWORD_FAIL });
		const message = error.response && error.response.data;
		throw message;
	}
};

export const resetPassword = (resetData) => async (dispatch) => {
	try {
		const response = await authService.resetPassword(resetData);
		if (response.status >= 200 && response.status < 300) {
			dispatch({ type: RESET_PASSWORD_SUCCESS });
		}
	} catch (error) {
		dispatch({ type: RESET_PASSWORD_FAIL });
		const message = error.response && error.response.data;
		throw message;
	}
};

export const activateNewAccountVerifyPhone =
	(code, token) => async (dispatch) => {
		try {
			const response = await authService.verifyPhoneOnSignup(code, token);
			if (response.status >= 200 && response.status < 300) {
				dispatch({
					type: VERIFY_PHONE,
				});
			}
		} catch (error) {
			const errorData = error.response && error.response.data;
			throw errorData.error;
		}
	};

export const getNewCodeSignupActivation = (token) => async (dispatch) => {
	try {
		const response = await authService.getPhoneVerificationCodeOnSignup(token);
		if (response.status >= 200 && response.status < 300) {
			dispatch({
				type: GET_NEW_CODE,
			});
		}
	} catch (error) {
		const errorData = error.response && error.response.data;
		console.log(errorData);
		throw errorData;
	}
};

export const sendVerificationLinkToEmail = (token) => async (dispatch) => {
	try {
		const response = await authService.sendEmailVerificationOnSignup(token);
		if (response.status >= 200 && response.status < 300) {
			dispatch({
				type: SEND_VERIFICATION_LINK,
			});
		}
	} catch (error) {
		const errorData = error.response && error.response.data;
		console.log(errorData);
		throw errorData;
	}
};

export const getHospitalList = () => async (dispatch) => {
	try {
		const response = await authService.getListHospitals();
		const hospitals = response.data.hospitals;
		if (response.status >= 200 && response.status < 300) {
			dispatch({ type: GET_HOSPITAL_LIST, data: hospitals });
		}
	} catch (error) {
		dispatch({ type: GET_HOSPITAL_LIST_ERROR });
		const message = error.response && error.response.data;
		throw message;
	}
};

export const setNewAdminPassword = (data) => async (dispatch) => {
	try {
		const response = await authService.setNewAdminPassword(data);
		if (response.status >= 200 && response.status < 300) {
			dispatch({ type: NEW_ADMIN_PASSWORD });
		}
	} catch (error) {
		dispatch({ type: NEW_ADMIN_PASSWORD_ERROR });
		const message = error.response && error.response.data;
		throw message;
	}
};

export const submitMessage = (data) => async (dispatch) => {
	try {
		const response = await authService.submitMessage(data);
		if (response.status >= 200 && response.status < 300) {
			dispatch({ type: SUBMIT_MESSAGE });
		}
	} catch (error) {
		dispatch({ type: NEW_ADMIN_PASSWORD_ERROR });
		const message = error.response && error.response.data;
		throw message;
	}
};

export const submitNewsletter = (email) => async (dispatch) => {
	try {
		const response = await authService.submitNewsletter(email);
		if (response.status >= 200 && response.status < 300) {
			dispatch({ type: 'SUBMIT_NEWS_LETTER' });
		}
	} catch (error) {
		dispatch({ type: 'SUBMIT_NEWS_LETTER_ERROR' });
		const message = error.response && error.response.data;
		throw message;
	}
};
