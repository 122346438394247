import _ from 'lodash';

import CartItem from '../../models/cart-item';
import {
	ADD_QTY,
	ADD_TO_CART,
	CLEAR_CART,
	GET_CART,
	REMOVE_FROM_CART,
	SUBTRACT_QTY,
	SET_PROMO_CODE_VALIDITY,
	CREATE_CHECKOUT_REQUEST,
	CREATE_CHECKOUT_SUCCESS,
	CREATE_CHECKOUT_FAILURE
} from './types';

const initialState = {
	items: {},
	count: 0,
	fetched: false,
	creatingCheckout: false,
	clientSecret: '',
	promoCode: '',
	discount: '',
	invalidPromoCode: false,
	fetchedIntent: false,
};

function cartReducer(state = initialState, action) {
	let updatedCartItems, mealItem;

	switch (action.type) {
		case CREATE_CHECKOUT_REQUEST:
			return {
				...state,
				creatingCheckout: true,
			};
		case CREATE_CHECKOUT_FAILURE:
		case CREATE_CHECKOUT_SUCCESS:
			return {
				...state,
				creatingCheckout: false,
			};
		case SET_PROMO_CODE_VALIDITY:
			return {
				...state,
				invalidPromoCode: action.invalidPromoCode
			};
		case 'GET_PAYMENT_INTENT_REQUEST':
			return {
				...state,
				fetchedIntent: false
			};
		case 'GET_PAYMENT_INTENT_FAILURE':
			return !action.data.invalidPromoCode ? initialState : {
				...state,
				invalidPromoCode: action.data.invalidPromoCode,
				fetchedIntent: true
			};
		case 'GET_PAYMENT_INTENT_SUCCESS':
			return {
				...state,
				clientSecret: action.data.clientSecret,
				discount: action.data.discount,
				promoCode: action.data.promoCode,
				fetchedIntent: true,
				invalidPromoCode: false,
			};
		case GET_CART:
			let fetchedCartItems = {};
			let count = 0;

			action.meals.forEach((meal) => {
				count += meal.meal_qty;
				const mealImage =
					meal.images.length ? meal?.images[0]?.url : meal?.images[1]?.url;

				fetchedCartItems = {
					...fetchedCartItems,
					[meal.id]: new CartItem(
						meal.meal_qty,
						mealImage,
						meal.name,
						null,
						meal.id,
						meal.attributes,
						meal.price,
						meal.productType,
						meal.metadata.find(attr => attr.key === 'featured_key')?.value,
						meal.variantId
					),
				};
			});
			return {
				...state,
				items: {
					...state.items,
					...fetchedCartItems,
				},
				count,
				promoCode: action.promoCode,
				discount: action.discount,
				clientSecret: action.data,
				fetched: true,
			};
		// case GET_PAYMENT_INTENT:
		//   return {
		//     clientSecret: action.data,

		//   }
		case ADD_TO_CART:
			const addedMeal = action.meal;
			// console.log('addedMeal', addedMeal)
			const { id: mealId, variantId, name, tags, img, id, price, productType, featured } = addedMeal;

			let qty = action.lines.find(({ variant }) => variantId === variant.id).quantity;

			if (state.items && state.items[mealId]) {
				// "Already a cart item"
				const cartItem = state.items[mealId];
				qty = cartItem.quantity + 1;
			}
			const updatedOrNewCartItem = new CartItem(
				qty,
				img,
				name,
				tags,
				id,
				null,
				price,
				productType,
				featured,
				variantId,
			);

			const items = {
				...state.items,
				[mealId]: updatedOrNewCartItem
			}

			const updatedItems = _.pickBy(items, ({ variantId }) =>
				!!action.lines.find(({ variant }) => variantId === variant.id))

			return {
				...state,
				items: updatedItems,
				count: action.quantity
			};

		case SUBTRACT_QTY:
			mealItem = state.items[action.mealId];

			updatedCartItems = {
				...state.items,
				[action.mealId]: new CartItem(
					action.qty,
					mealItem.mealPicture,
					mealItem.mealName,
					mealItem.tags,
					mealItem.id,
					null,
					mealItem.price,
					mealItem.productType,
					mealItem.featured,
					action.variantId,
				),
			};

			return {
				...state,
				items: updatedCartItems,
				count: Object.values(updatedCartItems).reduce((acc, { quantity }) => acc + quantity, 0) || 0,
			};

		case ADD_QTY:
			const itemQtyAdded = state.items[action.mealId];
			const updatedCartItem = new CartItem(
				action.qty,
				itemQtyAdded.mealPicture,
				itemQtyAdded.mealName,
				itemQtyAdded.tags,
				itemQtyAdded.id,
				null,
				itemQtyAdded.price,
				itemQtyAdded.productType,
				itemQtyAdded.featured,
				action.variantId,
			);

			updatedCartItems = {
				...state.items,
				[action.mealId]: updatedCartItem,
			};

			return {
				...state,
				items: updatedCartItems,
				count: Object.values(updatedCartItems).reduce((acc, { quantity }) => acc + quantity, 0) || 0,
			};

		case REMOVE_FROM_CART:
			mealItem = state.items[action.mealId];

			updatedCartItems = { ...state.items };
			delete updatedCartItems[action.mealId];

			return {
				...state,
				items: updatedCartItems,
				count: state.count - mealItem.quantity,
			};

		case CLEAR_CART:
			return initialState;

		default:
			return state;
	}
}

export default cartReducer;
