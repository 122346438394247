import React from 'react'
import { IntercomProvider } from 'react-use-intercom';
import "../formik/FormikReactSelect"
// NOTE: import custom for with react-select first to prevent conflict with react-use-intercom

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

const Intercom = ({children}) => {
    return (
        <IntercomProvider appId={INTERCOM_APP_ID}>
            {children}
        </IntercomProvider>
    )
}

export default Intercom
