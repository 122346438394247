export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
	SIGNUP_FAIL = 'SIGNUP_FAIL',
	LOGIN_SUCCESS = 'LOGIN_SUCCESS',
	LOGIN_FAIL = 'LOGIN_FAIL',
	LOGOUT = 'LOGOUT',
	REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS',
	REQUEST_RESET_PASSWORD_FAIL = 'REQUEST_RESET_PASSWORD_FAIL',
	RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL',
	SEND_VERIFICATION_LINK = 'SEND_VERIFICATION_LINK',
	GET_NEW_CODE = 'GET_NEW_CODE',
	VERIFY_PHONE = 'VERIFY_PHONE',
	GET_HOSPITAL_LIST = 'GET_HOSPITAL_LIST',
	GET_HOSPITAL_LIST_ERROR = 'GET_HOSPITAL_LIST_ERROR',
	NEW_ADMIN_PASSWORD = 'NEW_ADMIN_PASSWORD',
	NEW_ADMIN_PASSWORD_ERROR = 'NEW_ADMIN_PASSWORD_ERROR',
	SUBMIT_MESSAGE = 'SUBMIT_MESSAGE';
